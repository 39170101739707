import aboutMe from "../resources/text.js"
const AboutPage = () => {
    return (
        <>
            <h1> About Me</h1>
            <p> {aboutMe} </p>
        </>
    );
}

export default AboutPage;